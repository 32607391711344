import produce, { setAutoFreeze } from 'immer'
import * as actionTypes from '../Actions/actionTypes';
import moment from 'moment'

import { toSentenceCase } from '../../Shared/Helper/utility'
import { getStatusColor } from '../../Shared/Helper/MaterialTheme'

import jsonData from '../../Shared/Helper/Data'

setAutoFreeze(false)

const shipmentState = {
    shipments: [
        {
            "type": "OUTBOUND", "invoices": [{ "number": "INV123ABC2", "date": "2019-05-10", "value": "1000000", "cod_amt": "1000000", "eway_bill": "EWAB123C", "eway_bill_exp": "2019-05-30" }], "descriptions": [{ "name": "Test Product", "weight": "200", "unit": "Cartons", "len": "600", "width": "400", "height": "500" }], "reference": "Test Product", "source": { "client": "jusda", "createdAt": "Wed, 20 May 2020 14:22:40 GMT", "email": [], "loc": "21.561803,72.986554", "name": "Delhi", "type": "src" }, "destination": { "client": "jusda", "createdAt": "Wed, 20 May 2020 14:22:42 GMT", "loc": "22.890629,72.579633", "name": "AHMEDABAD", "type": "dest" }, "service": "FTL", "shipment_weight": "20", "shipment_unit": "Tonnes", "shipment_billable_weight": "19.9", "shipment_billable_unit": "Tonnes", "volume": "19.5", "volume_unit": "Tonnes", "payment": "To Bill", "pickup_time": "01:30", "pickup_date": "2020-05-25", "tat": "5", "permit": false, "notes": null, "shipment_no": "SHPJSD800BD2C16F", "state": "OPEN", "srcname": "Delhi", "destname": "AHMEDABAD", "total_weight": "20 - Tonnes", "total_volume": "19.5 - Tonnes", "disabled": false, "create": true, "transporter_name": 'NA', "transport_method": "LAND"
        },
        { "type": "OUTBOUND", "invoices": [{ "number": "INV123ABC2", "date": "2019-05-12", "value": "1000000", "cod_amt": "1000000", "eway_bill": "EWAB123C", "eway_bill_exp": "2019-05-30" }], "descriptions": [{ "name": "Test Product", "weight": "200", "unit": "Cartons", "len": "600", "width": "400", "height": "500" }], "reference": "Test Product", "source": { "client": "jusda", "createdAt": "Wed, 20 May 2020 14:22:40 GMT", "email": [], "loc": "21.561803,72.986554", "name": "Delhi", "type": "src" }, "destination": { "client": "jusda", "createdAt": "Wed, 20 May 2020 14:22:42 GMT", "loc": "18.436074,73.971249", "name": "PUNE", "type": "dest" }, "service": "FTL", "shipment_weight": "20", "shipment_unit": "Tonnes", "shipment_billable_weight": "19.9", "shipment_billable_unit": "Tonnes", "volume": "19.5", "volume_unit": "Tonnes", "payment": "To Bill", "pickup_time": "11:30", "pickup_date": "2020-05-12", "tat": "3", "permit": false, "notes": null, "shipment_no": "SHPJSD800BD2C15F", "state": "PENDING", "srcname": "Delhi", "destname": "PUNE", "total_weight": "20 - Tonnes", "total_volume": "19.5 - Tonnes", "disabled": false, "create": false, "transporter_name": 'NA', "transport_method": "AIR" },
        {
            "type": "OUTBOUND", "invoices": [{ "number": "INV123ABC2", "date": "2019-05-25", "value": "1000000", "cod_amt": "1000000", "eway_bill": "EWAB123C", "eway_bill_exp": "2019-06-05" }], "descriptions": [{ "name": "Test Product", "weight": "200", "unit": "Cartons", "len": "600", "width": "400", "height": "500" }], "reference": "Test Product", "source": { "client": "jusda", "createdAt": "Wed, 20 May 2020 14:22:40 GMT", "email": [], "loc": "21.561803,72.986554", "name": "Delhi", "type": "src" }, "destination": { "client": "jusda", "createdAt": "Wed, 20 May 2020 14:22:42 GMT", "loc": "12.945148, 77.573217", "name": "Bangalore", "type": "dest" }, "service": "FTL", "shipment_weight": "20", "shipment_unit": "Tonnes", "shipment_billable_weight": "19.9", "shipment_billable_unit": "Tonnes", "volume": "19.5", "volume_unit": "Tonnes", "payment": "To Bill", "pickup_time": "10:30", "pickup_date": "2020-05-25", "tat": "5", "permit": false, "notes": null, "shipment_no": "SHPJSDA47CA1ACDC", "state": "ACCEPTED", "srcname": "Delhi", "destname": "Bangalore", "total_weight": "20 - Tonnes", "total_volume": "19.5 - Tonnes", "disabled": true, "create": true,
            "transporter": { "_id": "5ec695d7a6e51d14adf7c4c2", "acceptanceRate": "80 %", "clients": ["jusda"], "createdAt": "Thu, 21 May 2020 14:53:03 GMT", "created_by": "jusda", "email": ["mrinal@intugine.com"], "freightRate": 20500, "marketRate": 14500, "marketTAT": "22 Hrs", "name": "Transporter A", "phone": "9962734334", "placementTime": "4 hrs", "relay": "no", "sla": "yes", "stages": [], "stdTat": 2.5, "tat": "24 Hrs", "track": "yes" }, "transporter_name": "Transporter A, Transporter B, Transporter C", "transport_method": "LAND + AIR", "lrFile": "https://intugine-tms.s3.ap-south-1.amazonaws.com/LR/test-7530100000297-hdhsjsu38833.pdf", "lr_no": "LRNJSDA47CA1ACDC"
        }
    ].map(m => (
        {
            ...m,

            reportingTime: moment(m.pickup_date).format("DD MMM'YY @HH:mm"),
            status: { text: toSentenceCase(m.state), color: getStatusColor(m.state) },
            transporterName: m.transporter && m.transporter.name,
        }
    )),
    counters: jsonData.result.shipmentCounters.map((m, mdx) => (
        {
            ...m,
            color: getStatusColor(m.label),
            active: mdx === 0 && true
        }
    ))
}

const updateShipmentActivecounterReducer = (state, action) => {

    state.counters.forEach(counter => {
        if (action.label === counter.label) {
            counter.active = true
        } else {
            counter.active = false
        }
    })
}


const shipmentReducer = produce((draft, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SHIPMENT_ACTIVE_COUNTER:
            updateShipmentActivecounterReducer(draft, action)
            break
        default: return draft
    }
}, shipmentState)

export default shipmentReducer