import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

import { getColor } from '../../../Shared/Helper/MaterialTheme'

const ChevronDown = (props) => {
    const {
        mycolor = "primary.main"
    } = props

    return (
        <SvgIcon {...props}>
            <path d="M6 9L12 15L18 9" stroke={getColor(mycolor)} strokeWidth="2" fill="none" />
        </SvgIcon>
    )
}

export default ChevronDown
