import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Typography, Box } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: props => ({
        color: props.textcolor ? theme.palette[props.textcolor.split('.')[0]][props.textcolor.split('.')[1]] : null,
        marginRight: theme.spacing(1),
        textTransform: 'none',
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: "transparent"
        }
    }),
    navLink: {
        textDecoration: 'none',
    },
    activeNavLink: {
        textDecoration: 'none',
        '& button': {
            '&::after': {
                content: '""',
                position: 'absolute',
                top: '80%',
                left: '45%',
                width: '1.25vw',
                height: '3px',
                marginTop: '-1px',
                borderRadius: '12px',
                background: theme.palette.secondary.dark,
            }
        }
    },
    icon: {
        marginTop: theme.spacing(0.5)
    },
    buttonText: {
        textDecoration: 'none'
    }
}))

const NavButton = (props) => {
    const classes = useStyles(props)
    const { children, to, textVariant, ...restProps } = props
    return (
        <NavLink to={to} exact className={classes.navLink} activeClassName={classes.activeNavLink}>
            <Grid wrap="nowrap" container className={classes.root} justify="center" alignItems="center" disableFocusRipple disableRipple disableTouchRipple component={Button} size="small" {...restProps}>
                <Grid item >
                    <Typography className={classes.children} fontWeight="600" align="center" component="h6" variant='subtitle1'>
                        <Box pl={1}>{children}</Box>
                    </Typography>
                </Grid>
            </Grid>
        </NavLink>
    )
}

export default NavButton
