import produce from 'immer'

import * as actionTypes from '../Actions/actionTypes';

const alertState = {
    hasAlert: false,
    alertText: {}
}

const setAlertReducer = (state, action) => {
    state.hasAlert = true
    state.alertText = action.alert
}

const resetAlertReducer = (state) => {
    state.hasAlert = false
    state.alertText = {}
}

const alertReducer = produce((draft, action) => {
    switch (action.type) {
        case actionTypes.SET_ALERT_MESSAGE:
            setAlertReducer(draft, action)
            break
        case actionTypes.RESET_ALERT_MESSAGE:
            resetAlertReducer(draft)
            break
        default: return draft
    }
}, alertState)

export default alertReducer