import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

import { getColor } from '../../../Shared/Helper/MaterialTheme'

const Profile = (props) => {

    const {
        mycolor = "common.white"
    } = props

    return (
        <SvgIcon {...props} viewBox='0 0 50 50'>
            <path d="M25 0C11.215 0 0 11.215 0 25C0 38.785 11.215 50 25 50C38.785 50 50 38.785 50 25C50 11.215 38.785 0 25 0ZM25 46.0422C13.3971 46.0422 3.95781 36.6022 3.95781 25C3.95781 13.3978 13.3971 3.95781 25 3.95781C36.6022 3.95781 46.0422 13.3984 46.0422 25.0007C46.0422 36.6029 36.6022 46.0422 25 46.0422Z" fill={getColor(mycolor)} stroke={getColor(mycolor)} />
            <path d="M25 29.436C17.969 29.436 11.3905 32.9617 6.47754 39.3628L9.61738 41.7718C13.7645 36.3694 19.2275 33.3939 25 33.3939C30.7725 33.3939 36.2354 36.3694 40.3819 41.7718L43.5218 39.3628C38.6088 32.9617 32.031 29.436 25 29.436Z" stroke={getColor(mycolor)} fill={getColor(mycolor)} />
            <path d="M24.9997 8.97095C19.3256 8.97095 14.7095 13.6167 14.7095 19.3271C14.7095 25.0374 19.3256 29.6833 24.9997 29.6833C30.6738 29.6833 35.2899 25.0375 35.2899 19.3272C35.2899 13.6168 30.6738 8.97095 24.9997 8.97095ZM24.9997 25.7255C21.5083 25.7255 18.6673 22.8555 18.6673 19.3271C18.6673 15.7987 21.5083 12.9287 24.9997 12.9287C28.4911 12.9287 31.3321 15.7994 31.3321 19.3271C31.3321 22.8548 28.4911 25.7255 24.9997 25.7255Z" stroke={getColor(mycolor)} fill={getColor(mycolor)} />
        </SvgIcon>
    )
}

export default Profile
