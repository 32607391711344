import * as actiontypes from './actionTypes'
import {client as ClientService} from '../../Shared/API/services'

const startFetchIndents = () => ({
    type: actiontypes.START_FETCH_INDENT
})

const finishFetchIndents = () => ({
    type: actiontypes.FINISH_FETCH_INDENT
})

const saveFilters = (filters) => ({
    type: actiontypes.SAVE_FILTERS,
    filters
})

const setIndentData = (indents) => ({
    type: actiontypes.UPDATE_INDENT_DATA,
    ...indents
})

const setVehicleTypeDate = (vehicleData) => ({
    type: actiontypes.UPDATE_VEHICLE_TYPE_DATA,
    vehicleData
})

const setCustomerData = (customerData) => ({
    type: actiontypes.UPDATE_CUSTOMER_DATA,
    customerData
})

const setSrcLocationData = (sourceLocations) => ({
    type: actiontypes.UPDATE_SRC_LOCATION_DATA,
    sourceLocations
})

const setDestLocationData = (destinationLocations) => ({
    type: actiontypes.UPDATE_DEST_LOCATION_DATA,
    destinationLocations
})

const setLocationData = (allLocations) => ({
    type: actiontypes.UPDATE_LOCATION_DATA,
    allLocations
})

const setTransporterData = (transporterList) => ({
    type: actiontypes.UPDATE_TRANSPORTER_DATA,
    transporterList
})

export const setPaginationData = (pagination) => ({
    type: actiontypes.UPDATE_PAGINATION_DATA,
    pagination
})

export const clearIndents = () => ({
    type: actiontypes.RESET_INDENT_DATA
})

export const clearTransporters = () => ({
    type: actiontypes.RESET_TRANSPORTER_DATA
})

export const clearDestination = () => ({
    type: actiontypes.RESET_DESTINATION_DATA
})

export const clearCustomers = () => ({
    type: actiontypes.RESET_CUSTOMER_DATA
})

export const updateIndentActiveCounter = (label) => (
    {
        type: actiontypes.UPDATE_INDENT_ACTIVE_COUNTER,
        label
    }
)

export const createIndentResponse = (response, ReqType) => {
    if(response.error){
        return {
            type : actiontypes.CREATE_INDENT_FAILURE,
            response,
            ReqType
        }
    }
    else if(response.response.display.message === "Partial Success"){
        return {
            type : actiontypes.CREATE_INDENT_PARTIAL_FAILURE,
            response
        }
    }
    else{
        return {
            type : actiontypes.CREATE_INDENT_SUCCESS,
            ReqType
        }
    }
}

export const editIndentResponse = (response) => {
    console.log(response, "edit indent response")
    return {
        type : actiontypes.EDIT_INDENT_RESPONSE,
        response
    }
}

export const reInitIndentResponse = (response) => {
    if(response.error){
        return {
            type : actiontypes.REINIT_INDENT_FAILURE,
            response
        }
    }
    else{
        return {
            type : actiontypes.REINIT_INDENT_SUCCESS,
        }
    }
}

export const cancelIndentResponse = (response) => {
    if(response.error){
        console.log(response, "Cancel Indent")
        return {
            type : actiontypes.CANCEL_INDENT_FAILURE,
            response
        }
    }
    else{
        console.log(response, "Cancel Indent")
        return {
            type : actiontypes.CANCEL_INDENT_SUCCESS
        }
    }
}

export const addTransporterResponse = (response) => {
    if(response.response){
        return {
            type : actiontypes.ADD_TRANSPORTER_SUCCESS,
            response
        }
    }else{
        return {
            type : actiontypes.ADD_TRANSPORTER_FAILED,
            response
        }
    }
}

export const createLocationResponse = (response) => {
    if(response.response){
        return {
            type : actiontypes.CREATE_LOCATION_SUCCESS,
            response
        }
    }else if(response.error){
        return {
            type : actiontypes.CREATE_LOCATION_FAILED,
            response
        }
    }
}

export const addVehicleResponse = (response) => {
    console.log(response, "in the handler")
    return {
        type : actiontypes.ADD_VEHICLE_TYPE_RESPONSE,
        response
    }
}

export const lookupAddressResponse = (response) => {
    return {
        type : actiontypes.LOOKUP_ADDRESS_RESPONSE,
        response
    }
}

export const resendEmailResponseHandler = (response) => {
    return {
        type : actiontypes.RESEND_EMAIL_RESPONSE,
        response
    }
}

export const reInitIndent = (params) => {
    return async dispatch => {
        const response = await ClientService.reInitIndent(params)
        if(response){
            dispatch(reInitIndentResponse(response))
        }
    }
}

export const cancelIndent = (params) => {
    return async dispatch => {
        const response = await ClientService.cancelIndent(params)
        if(response){
            dispatch(cancelIndentResponse(response))
        }
    }
}

export const resetState = () => {
    return async dispatch => dispatch({type : actiontypes.RESET_STATE})
}

export const getIndents = (state,search,limit,offset,filters) => {
    return async dispatch => {
        dispatch(startFetchIndents())
        const {response} = await ClientService.fetchIndent(state,search,limit,offset,filters)
        if(response) {
            dispatch(setIndentData({...response.data[0]}))
        }
        dispatch(finishFetchIndents())
    }
}

export const getFilteredIndents = (params) => {
    return async dispatch => {
        dispatch(saveFilters(params))
    }
}

export const getVehicleType = (params) => {
    return async dispatch => {
        const {response} = await ClientService.fetchVehicleType(params)
        if(response) {
            dispatch(setVehicleTypeDate(response.data))
        }
    }
}

export const getFilteredVehicleType = (params) => {
    return async dispatch => {
        const {response} = await ClientService.getFilteredVehicleType(params)
        if(response) {
            dispatch(setVehicleTypeDate(response.data))
        }
    }
}

export const updateTruckType = (params) => {
    return async dispatch => {
        const {response} = await ClientService.updateVehicleType(params)
        if(response) {
            dispatch(getVehicleType())
        }
    }
}

export const deleteTruckType = (params) => {
    return async dispatch => {
        const {response} = await ClientService.deleteTruckType(params)
        if(response) {
            dispatch(getVehicleType())
        }
    }
}

export const getSrcLocation = () => {
    return async dispatch => {
        const {response} = await ClientService.fetchSource()
        if(response) {
            dispatch(setSrcLocationData(response.data))
        }
    }
}

export const getDestLocation = (params) => {
    return async dispatch => {
        const {response} = await ClientService.fetchDestination(params)
        if(response) {
            dispatch(setDestLocationData(response.data))
        }
    }
}

export const getCustomers = (params) => {
    return async dispatch => {
        const {response} = await ClientService.fetchCustomer(params)
        if(response) {
            dispatch(setCustomerData(response.data))
        }
    }
}

export const addCustomer = (params) => {
    return async dispatch => {
        const {response} = await ClientService.addCustomer(params)
        if(response) {
            dispatch(getCustomers())
        }
    }
}

export const getFilteredCustomers = (params) => {
    return async dispatch => {
        const {response} = await ClientService.getFilteredCustomers(params)
        if(response) {
            dispatch(setCustomerData(response.data))
        }
    }
}

export const updateCustomer = (params) => {
    return async dispatch => {
        const {response} = await ClientService.updateCustomer(params)
        if(response) {
            dispatch(getCustomers())
        }
    }
}

export const deleteCustomer = (params) => {
    return async dispatch => {
        const {response} = await ClientService.deleteCustomer(params)
        if(response) {
            dispatch(getCustomers())
        }
    }
}

export const getTransporter = (params) => {
    return async dispatch => {
        const {response} = await ClientService.fetchTransporters(params)
        if(response) {
            dispatch(setTransporterData(response.data))
        }
    }
}

export const getFilteredTransporters = (params) => {
    return async dispatch => {
        const {response} = await ClientService.getFilteredTransporters(params)
        if(response) {
            dispatch(setTransporterData(response.data))
        }
    }
}

export const updateTransporter = (params) => {
    return async dispatch => {
        const {response} = await ClientService.updateTransporter(params)
        if(response) {
            dispatch(getTransporter())
        }
    }
}

export const deleteTransporter = (params) => {
    return async dispatch => {
        const {response} = await ClientService.deleteTransporter(params)
        if(response) {
            dispatch(getTransporter())
        }
    }
}


export const getLocations = (params) => {
    return async dispatch => {
        const {response} = await ClientService.fetchLocations(params)
        if(response) {
            dispatch(setLocationData(response.data))
        }
    }
}

export const getFilteredLocations = (params) => {
    return async dispatch => {
        const {response} = await ClientService.getFilteredLocations(params)
        if(response) {
            dispatch(setLocationData(response.data))
        }
    }
}

export const updateLocation = (payload) => {
    return async dispatch => {
        const {response} = await ClientService.updateLocation(payload)
        if(response) {
            dispatch(getLocations())
        }
    }
}

export const deleteLocation = (params) => {
    return async dispatch => {
        const {response} = await ClientService.deleteLocation(params)
        if(response) {
            dispatch(getLocations())
        }
    }
}

export const createIndent = (params, type) => {
    return async dispatch => {
        const response = await ClientService.createIndent(params)
        if(response){
            dispatch(createIndentResponse(response, type))
            dispatch(getIndents('total', null, 10, 0))
        }
    }
}

export const editIndent = (params) => {
    return async dispatch => {
        const response = await ClientService.editIndent(params)
        if(response){
            dispatch(editIndentResponse(response))
            dispatch(getIndents('total', null, 10, 0))
        }
    }
}

export const addTransporter = (payload) => {
    return async dispatch => {
        const response = await ClientService.addTransporter(payload)
        if(response){
            console.log(response,"transporter add")
            dispatch(addTransporterResponse(response))
        }
    }
}

export const addVehicleType = (payload) => {
    let data
    if(payload.mode){
        data = {
            "vehicle_type" : payload.mode
        }
    }else{
        data = payload
    }
    return async dispatch => {
        const response = await ClientService.createVehicleType(data)
        if(response){
            console.log(response, "Create new vehicle type")
            dispatch(addVehicleResponse(response))
            dispatch(getVehicleType())
        }
    }
}

export const lookupAddress = (address) => {
    return async dispatch => {
        const response = await ClientService.lookupAddress(address)
        if(response){
            console.log(response, "address")
            dispatch(lookupAddressResponse(response.response.data))
        }
    }
}

export const createLocation = (location) => {
    console.log("creating location", location)
    return async dispatch => {
        const response = await ClientService.createLocation(location)
        if(response){
            console.log(response, "location added/ not")
            dispatch(createLocationResponse(response))
            dispatch(getSrcLocation())
            dispatch(getDestLocation())
        }
    }
}

export const resendEmail = (indent) => {
    return async dispatch => {
        const response = await ClientService.resendEmail(indent)
        if(response){
            dispatch(resendEmailResponseHandler(response))
        }
    }
}