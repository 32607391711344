import * as actionTypes from '../Actions/actionTypes';
import produce from 'immer'

const initialState = {
    U_UID: null,
    TR_UID: null,
    user: null,
    client: null,
    isAuthenticated: false,
    viaLogin: false,
    hide_bar: false,
    inter_com: {},
    privilege: null,
    loginLoader: false,
    config: {}
}

const authStartReducer = (state) => {
    state.loginLoader = true
}

const authSuccessReducer = (state, action) => {
    state.U_UID = action.successData.token
    state.client = action.successData.user
    state.user = action.successData.user
}

const userVerifyReducer = (state, action) => {
    state.config = action.data
    state.inter_com = { ...action.data.config }
    state.privilege = [...(action.data.privilege || [])]
    state.isAuthenticated = true
    state.viaLogin = true
    state.loginLoader = false
    state.hide_bar = false
}

const authFailReducer = (state) => {
    state.loginLoader = false
}

const authLogoutReducer = () => {
    return initialState
}

const storeToken = (state, action) => {
    if(action.user === "/indents"){
        state.U_UID = action.token
        state.user = action.user
        state.config = action.data
        state.isAuthenticated = true
        state.hide_bar = true
    }else{
        state.TR_UID = action.token
        state.user = "transporter"
        state.config = action.data
        state.isAuthenticated = true
    }
}

const authReducer = produce((draft, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            authStartReducer(draft)
            break

        case actionTypes.AUTH_SUCCESS:
            authSuccessReducer(draft, action)
            break

        case actionTypes.USER_VERIFY:
            userVerifyReducer(draft, action)
            break

        case actionTypes.AUTH_FAIL:
            authFailReducer(draft)
            break

        case actionTypes.AUTH_LOGOUT:
            return authLogoutReducer()

        case actionTypes.STORE_USER_TOKEN:
            storeToken(draft, action)
            break;

        default: return draft
    }
}, initialState)

export default authReducer;