export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}

export const getDisplayStatus = (statusObj) => {

    const { status } = statusObj

    if (status >= 200 && status < 300 && status !== 207) {
        statusObj.type = "success"
    } else if (status === 207) {
        statusObj.type = "warning"
    } else if (status > 400) {
        statusObj.type = "error"
    }

    return statusObj
}


export const toSentenceCase = (string) => {
    const value = string.trim()
    if (!Boolean(value)) {
        return ''
    } else {
        return value.toLowerCase().split(' ')
            .map(word => word.replace(word[0], word[0].toUpperCase())).join(' ')
    }
}

export const isObject = (object) => object && typeof object === "object" && object.constructor === Object;

export const keyDownHandler = (event) => {
    event.persist()
    if (event.target.type === 'number') {
        if (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 69 || event.keyCode === 190 || event.keyCode === 187 || event.keyCode === 189) {
            event.preventDefault()
        }
    }
}

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}