/**
 * Action Types for Authentication actions
 * @exports - Action Type for auth module
 */

export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const AUTH_USER = "AUTH_USER";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const USER_VERIFY = "USER_VERIFY";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const CLEAR_ALERT_DATA = "CLEAR_ALERT_DATA";
export const STORE_USER_TOKEN = "STORE_USER_TOKEN";

/**
 * Action Types for User Indent actions
 * @exports  - Action Type for user module
 */

export const UPDATE_INDENT_ACTIVE_COUNTER = "UPDATE_INDENT_ACTIVE_COUNTER";
export const START_FETCH_INDENT = "START_FETCH_INDENT";
export const FINISH_FETCH_INDENT = "FINISH_FETCH_INDENT";
export const UPDATE_INDENT_DATA = "UPDATE_INDENT_DATA";
export const SAVE_FILTERS = "SAVE_FILTERS";
export const UPDATE_VEHICLE_TYPE_DATA = "UPDATE_VEHICLE_TYPE_DATA";
export const UPDATE_SRC_LOCATION_DATA = "UPDATE_SRC_LOCATION_DATA";
export const UPDATE_DEST_LOCATION_DATA = "UPDATE_DEST_LOCATION_DATA";
export const UPDATE_LOCATION_DATA = "UPDATE_LOCATION_DATA";
export const UPDATE_TRANSPORTER_DATA = "UPDATE_TRANSPORTER_DATA";
export const RESET_TRANSPORTER_DATA = "RESET_TRANSPORTER_DATA";
export const RESET_DESTINATION_DATA = "RESET_DESTINATION_DATA";
export const RESET_INDENT_DATA = "RESET_INDENT_DATA";
export const UPDATE_PAGINATION_DATA = "UPDATE_PAGINATION_DATA";
export const CREATE_INDENT = "CREATE_INDENT";
export const CREATE_INDENT_SUCCESS = "CREATE_INDENT_SUCCESS";
export const CREATE_INDENT_FAILURE = "CREATE_INDENT_FAILURE";
export const CANCEL_INDENT_SUCCESS = "CANCEL_INDENT_SUCCESS";
export const CANCEL_INDENT_FAILURE = "CANCEL_INDENT_FAILURE";
export const REINIT_INDENT_SUCCESS = "REINIT_INDENT_SUCCESS";
export const REINIT_INDENT_FAILURE = "REINIT_INDENT_FAILURE";
export const CREATE_INDENT_PARTIAL_FAILURE = "CREATE_INDENT_PARTIAL_FAILURE";
export const EDIT_INDENT_RESPONSE = "EDIT_INDENT_RESPONSE";
export const RESET_STATE = "RESET_STATE";
export const ADD_TRANSPORTER_SUCCESS = "ADD_TRANSPORTER_SUCCESS";
export const ADD_TRANSPORTER_FAILED = "ADD_TRANSPORTER_FAILED";
export const LOOKUP_ADDRESS_RESPONSE = "LOOKUP_ADDRESS_RESPONSE";
export const ADD_VEHICLE_TYPE_RESPONSE = "ADD_VEHICLE_TYPE_RESPONSE";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_FAILED = "CREATE_LOCATION_FAILED";
export const RESEND_EMAIL_RESPONSE = "RESEND_EMAIL_RESPONSE";
export const UPDATE_CUSTOMER_DATA = "UPDATE_CUSTOMER_DATA";
export const RESET_CUSTOMER_DATA = "RESET_CUSTOMER_DATA";

/**
 * Action Types for User Shipment actions
 * @exports  - Action Type for shipment module
 */

export const UPDATE_SHIPMENT_ACTIVE_COUNTER = "UPDATE_SHIPMENT_ACTIVE_COUNTER";

/**
 * Action Types for Transporter Indent actions
 * @exports  - Action Type for transporter module
 */

export const TRANSPORTER_UPDATE_AVAILABLE_VEHICLES =
  "TRANSPORTER_UPDATE_AVAILABLE_VEHICLES";
export const INDENT_ACCEPT_RESPONSE = "INDENT_ACCEPT_RESPONSE";
export const INDENT_REJECT_RESPONSE = "INDENT_REJECT_RESPONSE";
export const ADD_DRIVER_DETAILS_SUCCESS = "ADD_DRIVER_DETAILS_SUCCESS";
export const ADD_DRIVER_DETAILS_FAILURE = "ADD_DRIVER_DETAILS_FAILURE";
export const ADD_VEHICLE_DETAILS_SUCCESS = "ADD_VEHICLE_DETAILS_SUCCESS";
export const ADD_VEHICLE_DETAILS_FAILURE = "ADD_VEHICLE_DETAILS_FAILURE";
export const START_TRANSPORTER_FETCH_INDENT = "START_TRANSPORTER_FETCH_INDENT";
export const FINISH_TRANSPORTER_FETCH_INDENT =
  "FINISH_TRANSPORTER_FETCH_INDENT";
export const UPDATE_TRANSPORTER_INDENT_DATA = "UPDATE_TRANSPORTER_INDENT_DATA";
export const UPDATE_TRANSPORTER_ACTIVE_COUNTER =
  "UPDATE_TRANSPORTER_ACTIVE_COUNTER";
export const RESET_TRANSPORTER_INDENT_DATA = "RESET_TRANSPORTER_INDENT_DATA";
export const UPDATE_TRANSPORTER_PAGINATION_DATA =
  "UPDATE_TRANSPORTER_PAGINATION_DATA";
export const UPDATE_VEHICLE_DOCUMENTS = "UPDATE_VEHICLE_DOCUMENTS";
export const SET_LOADING = "SET_LOADING";
/**
 * Action Types for Alert Message actions
 * @exports  - Action Type for alert module
 */

export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const RESET_ALERT_MESSAGE = "RESET_ALERT_MESSAGE";

export const UPDATE_INDENT_CREATE_DATA = "UPDATE_INDENT_CREATE_DATA";
