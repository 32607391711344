import axios from "axios";

import { getDisplayStatus } from "../Helper/utility";

export const url = "https://api.indent.intusystems.info/api/v1";

const api = axios.create({
  baseURL: url,
});

let cancelRequest;
let CancelToken = axios.CancelToken;

api.interceptors.request.use((config) => {
  const ls = localStorage.getItem("persist:IMS");
  let token = null;

  if (ls) {
    const data = JSON.parse(ls);
    const auth = JSON.parse(data.auth);
    token =
      config.maker && config.maker === "transporter" ? auth.TR_UID : auth.U_UID;
  }

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  delete config.maker;

  return config;
});

api.interceptors.response.use(
  (response) => {
    return {
      data:
        response && response.data && response.data.result
          ? response.data.result
          : response.data.config,
      display: getDisplayStatus({
        status: response.status,
        message: response.data.message,
      }),
    };
  },
  (error) =>
    Promise.reject({
      display: getDisplayStatus({
        status: error.response.status,
        message: error.response.data.message,
        result:
          error.response && error.response.data && error.response.data.result,
      }),
    })
);

const request = {
  login: async ({ username, password }) => {
    try {
      const response = await api({
        url: "/login",
        method: "POST",
        auth: {
          username,
          password,
        },
      });
      return { response };
    } catch (error) {
      return { error };
    }
  },
  get: async (url, params, maker, takeLatest) => {
    if (takeLatest && cancelRequest) {
      cancelRequest();
    }
    try {
      const response = await api({
        url,
        method: "GET",
        params,
        maker,
        cancelToken: new CancelToken(function (c) {
          cancelRequest = c;
        }),
      });
      return { response };
    } catch (error) {
      return { error };
    }
  },
  post: async (url, data, maker, params) => {
    try {
      const response = await api({
        url,
        method: "POST",
        maker,
        params,
        data,
      });
      return { response };
    } catch (error) {
      return { error };
    }
  },
  delete: async (url, params, maker, takeLatest) => {
    if (takeLatest && cancelRequest) {
      cancelRequest();
    }
    try {
      const response = await api({
        url,
        method: "DELETE",
        params,
        maker,
        cancelToken: new CancelToken(function (c) {
          cancelRequest = c;
        }),
      });
      return { response };
    } catch (error) {
      return { error };
    }
  },
  update: async (url, data, maker) => {
    try {
      const response = await api({
        url,
        method: "PUT",
        maker,
        data,
      });
      return { response };
    } catch (error) {
      return { error };
    }
  },
};

export default request;
