import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const AlertSnack = (props) => {

    const { open, onClose, alert } = props

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        onClose()
    }

    return (
        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={4000} open={open} onClose={handleClose} >
            {alert.type && (
                <Alert onClose={handleClose} variant="filled" severity={alert.type}>
                    {alert.status} - {alert.message}
                </Alert>
            )}
        </Snackbar>
    )
}

export default AlertSnack
