import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'

import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './Shared/Helper/MaterialTheme'

import './index.css';
import App from './App';
import { store, persistor } from './Store/index'
import * as serviceWorker from './serviceWorker';

const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
