import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

import { getColor } from '../../../Shared/Helper/MaterialTheme'

const Exit = (props) => {

    const {
        mycolor = "common.white"
    } = props
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path d="M0.782621 12.7827L15.9132 12.7827C16.3452 12.7827 16.6958 12.4321 16.6958 12.0001C16.6958 11.5681 16.3452 11.2175 15.9132 11.2175L0.78262 11.2175C0.350618 11.2175 1.14747e-05 11.5681 1.15125e-05 12.0001C1.15503e-05 12.4321 0.350618 12.7827 0.782621 12.7827Z" stroke={getColor(mycolor)} fill={getColor(mycolor)} />
            <path d="M11.739 16.9564C11.9395 16.9564 12.1397 16.8804 12.2922 16.7269L16.466 12.5531C16.7717 12.2472 16.7717 11.7516 16.466 11.4459L12.2922 7.272C11.9865 6.9663 11.4908 6.9663 11.185 7.27201C10.8792 7.57771 10.8792 8.07334 11.185 8.37905L14.8059 11.9999L11.185 15.6209C10.8792 15.9266 10.8792 16.4222 11.185 16.7279C11.3384 16.8804 11.5388 16.9564 11.739 16.9564Z" stroke={getColor(mycolor)} fill={getColor(mycolor)} />
            <path d="M12.5217 23.4784C18.8504 23.4784 24 18.3288 24 12.0001C24 5.67136 18.8504 0.521729 12.5217 0.52173C7.77291 0.52173 3.58014 3.37875 1.83857 7.80118C1.67998 8.2028 1.87831 8.65677 2.27993 8.81631C2.68175 8.97299 3.13572 8.77772 3.29526 8.37399C4.79895 4.55476 8.42081 2.08695 12.5217 2.08695C17.9875 2.08695 22.4348 6.5344 22.4348 12.0001C22.4348 17.4657 17.9875 21.9132 12.5217 21.9132C8.42081 21.9132 4.79896 19.4453 3.29526 15.6273C3.13668 15.2234 2.6827 15.0283 2.27993 15.1848C1.87831 15.3433 1.67998 15.7983 1.83857 16.2001C3.58014 20.6214 7.77291 23.4784 12.5217 23.4784Z" stroke={getColor(mycolor)} fill={getColor(mycolor)} />
        </SvgIcon>
    )
}

export default Exit
