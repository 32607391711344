import { combineReducers } from 'redux'

import authReducer from './auth'
import alertReducer from './alert'
import indentReducer from './indent'
import shipmentReducer from './shipment'
import transporterReducer from './transporter'

const rootReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    indent: indentReducer,
    shipment: shipmentReducer,
    transporter: transporterReducer
})

export default rootReducer