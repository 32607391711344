import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import routes from "./router";
import * as axios from "axios";

import AlertSnack from "./Components/UI/Display/AlertSnack";
import NavBar from "./Containers/NavBar";
import SctNavBar from "./Containers/sctNavBar";

import * as actions from "./Store/Actions/index";

class App extends Component {
  render() {
    //Check to see if the user is authenticated or not

    let path = window.location.pathname;
    let search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get("token");
    if (token) {
      // const indent_id = urlParams.get('indent_id')
      axios
        .get("https://api.indent.intusystems.info/api/v1/verify?config=true", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => this.props.storeToken(token, path, res.data.config));
    }

    return (
      <div>
        <AlertSnack
          open={this.props.hasAlert}
          onClose={this.props.clearAlert}
          alert={this.props.alertText}
        />
        {this.props.hide_bar ? <SctNavBar /> : <NavBar />}
        <Switch>
          {routes.map(({ component: Component, ...route }, i) => (
            <Route
              key={`route-${i}-${route.path}`}
              component={(props) => <Component {...props} />}
              exact={route.exact ? route.exact : false}
              path={route.path}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasAlert: state.alert.hasAlert,
    alertText: state.alert.alertText,
    verified: state.auth.isAuthenticated,
    hide_bar: state.auth.hide_bar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAlert: () => dispatch(actions.resetAlert()),
    storeToken: (token, user, config) =>
      dispatch(actions.storeToken(token, user, config)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
