import request from "./request";

export const auth = {
  login: (credentials) => request.login(credentials),
  verify: (maker = "user") => request.get("/verify", { config: true }, maker),
};

export const client = {
  fetchIndent: (
    state,
    search = null,
    limit,
    offset,
    filters,
    maker = "user",
    takeLatest = true
  ) => {
    const param = { filters: filters?.filters };
    if (search) {
      param.search = search;
    }
    return request.post(
      `/indents/get?limit=${limit}&offset=${offset}&state=${state}&search=${search}`,
      param,
      maker,
      takeLatest
    );
  },
  createIndent: (payload, maker = "user") => {
    if (Array.isArray(payload)) {
      return request.post("/indents/new?bulk=true", payload, maker);
    } else {
      return request.post("/indents/new", payload, maker);
    }
  },
  editIndent: (payload, maker = "user") => {
    return request.post("/indents/edit", payload, maker);
  },
  reInitIndent: (payload, maker = "user") => {
    return request.post("/indents/reinitiate", payload, maker);
  },
  cancelIndent: (payload, maker = "user") => {
    return request.post("/indents/cancel", payload, maker);
  },
  fetchVehicleType: (params, maker = "user") =>
    request.get("/vehicle/type/get", { ...params }, maker),
  fetchSource: (maker = "user") =>
    request.get("/location/get", { type: "src" }, maker),
  fetchDestination: (params, maker = "user") =>
    request.get("/location/get", { ...params, type: "dest" }, maker),
  fetchLocations: (params) => request.get("/location/get", { ...params }),
  deleteLocation: (params) => request.delete("/location", params),
  deleteTransporter: (params) => request.delete("/transporters", params),
  deleteCustomer: (params) => request.delete("/customer", params),
  deleteTruckType: (params) => request.delete("/vehicle/type", params),
  fetchCustomer: (params, maker = "user") =>
    request.get("/customer", { ...params }, maker),
  fetchTransporters: (params, maker = "user") =>
    request.get("/transporters/get", { ...params }, maker),
  getFilteredIndents: (payload, maker = "user") => {
    return request.post("/indents/get", payload, maker);
  },
  getFilteredLocations: (payload, maker = "user") => {
    return request.post("/location/get", { filters: payload }, maker);
  },
  getFilteredTransporters: (payload, maker = "user") => {
    return request.post("/transporters/get", { filters: payload }, maker);
  },
  getFilteredCustomers: (payload, maker = "user") => {
    return request.post("/customer", { filters: payload }, maker);
  },
  getFilteredVehicleType: (payload, maker = "user") => {
    return request.post("/vehicle/type/get", { filters: payload }, maker);
  },
  addCustomer: (payload, maker = "user") => {
    return request.post("/customer/new", payload, maker);
  },
  updateCustomer: (payload, maker = "user") => {
    return request.update("/customer", payload, maker);
  },
  updateLocation: (payload, maker = "user") => {
    return request.update("/location", payload, maker);
  },
  updateTransporter: (payload, maker = "user") => {
    return request.update("/transporters", payload, maker);
  },
  updateVehicleType: (payload, maker = "user") => {
    return request.update("/vehicle/type", payload, maker);
  },
  downloadBulkInvoice: (payload, maker = "user") => {
    return request.post("indents/download/docs", payload);
  },
  addTransporter: (payload, maker = "user") => {
    return request.post("/transporters/new", payload);
  },
  createVehicleType: (payload, maker = "user") => {
    return request.post("/vehicle/type/new", payload);
  },
  lookupAddress: (payload, maker = "user") => {
    return request.get(`/location/search?address=${payload}`);
  },
  createLocation: (payload, maker = "user") => {
    return request.post("/location/new", payload, maker);
  },
  resendEmail: (payload, maker = "user") => {
    return request.post("/indents/resend", payload, maker);
  },
  downloadReport: (maker = "user") => {
    return request.get("/reports/download?limit=all", maker);
  },
};

export const transporter = {
  getIndents: (payload, maker = "transporter") => {
    return request.get("/transporters/indents", payload, maker);
  },
  acceptIndent: (payload, maker = "transporter") => {
    return request.post("/indents/accept", payload, maker);
  },
  rejectIndent: (payload, maker = "transporter") => {
    return request.post("/indents/reject", payload, maker);
  },
  addDriverDetails: (payload, maker = "transporter") => {
    return request.post("/transporters/driver/detail", payload, maker);
  },
  addVehicleDetails: (payload, maker = "transporter") => {
    return request.post("/transporters/driver/docs", payload, maker);
  },
  getFilteredIndents: (payload, params = {}, maker = "transporter") => {
    return request.post("/transporters/indents", payload, maker, params);
  },
  downloadReport: (maker = "transporter") => {
    return request.get("/reports/download?limit=all", maker);
  },
  sctTripStart: (payload, maker = "transporter") => {
    return request.get(`/sct/trip/start?${payload}`, null, maker);
  },
};
