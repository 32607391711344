import * as actionTypes from './actionTypes'
import {setAlert, resetAlert} from './alert'
import {auth as Auth} from '../../Shared/API/services'

const authStart = () => (
    {
        type: actionTypes.AUTH_START
    }
)

const authSuccess = (successData) => (
    {
        type: actionTypes.AUTH_SUCCESS,
        successData
    }
)

const userVerify = (data) => (
    {
        type: actionTypes.USER_VERIFY,
        data
    }
)

const storeVerifiedToken = (token, user, data) => ( //data refers to config file received for the user
    {
        type : actionTypes.STORE_USER_TOKEN,
        token,
        user,
        data
    }
)

export const auth = (authData) => (
    {
        type: actionTypes.AUTH_USER,
        ...authData
    }
)


export const logout = () => (
    {
        type: actionTypes.AUTH_LOGOUT
    }
)

const authFail = () => (
    {
        type: actionTypes.AUTH_FAIL
    }
)

const authError = error => dispatch => {
    dispatch(authFail())
    dispatch(setAlert(error.display))
}

const authUserVerify = history => {
    return async dispatch => {
        const {response: verifySuccess, error: verifyError} = await Auth.verify()
        if(verifySuccess) {
            // console.log(verifySuccess, "Successfully verified")
            dispatch(userVerify(verifySuccess.data))
            history.push('/indents')
        } else {
            dispatch(authError(verifyError))
        }
    }
}

export const storeToken = (token, user, config) => {
    return async dispatch => dispatch(storeVerifiedToken(token, user, config))
}

export const clearALertData = () => {
    return {
        type : actionTypes.CLEAR_ALERT_DATA
    }
}

export const authUser = action => {
    return async dispatch => {
        dispatch(resetAlert())
        dispatch(authStart())
        const auth = {
            username: action.username,
            password: action.password
        }
        const {response: loginSuccess, error: loginError} = await Auth.login(auth)

        if(loginSuccess) {
            dispatch(authSuccess(loginSuccess.data[0]))
            dispatch(clearALertData())
            setTimeout(() => dispatch(authUserVerify(action.history)), 100)
        } else {
            dispatch(authError(loginError))
        }
    }
}

