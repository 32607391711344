import produce from "immer";
import moment from "moment";

import * as actionTypes from "../Actions/actionTypes";
import { toSentenceCase } from "../../Shared/Helper/utility";
import { getStatusColor } from "../../Shared/Helper/MaterialTheme";

const transporterState = {
  indents: [],
  counters: [],
  tableLoading: false,
  totalData: 0,
  activeCounter: "total",
  pagination: {
    limit: 10,
    offset: 0,
  },
  loading: false,
  alertData: {
    open: false,
    type: "",
    status: "",
    message: "",
  },
};

const resetTransporterIndentReducer = (state) => {
  state.indents = [];
};

const setLoading = (state, action) => {
  state.loading = action;
};
const updateTransporterActivecounterReducer = (state, action) => {
  state.activeCounter = action.label;
  state.counters.forEach((counter) => {
    if (action.label === counter.label) {
      counter.active = true;
    } else {
      counter.active = false;
    }
  });
};

const updateTransporterIndentDataReducer = (state, action) => {
  if (action.data[0].counters) {
    state.counters = action.data[0].counters.map((m, mdx) => ({
      ...m,
      color: getStatusColor(m.label),
      active: mdx === 0 && true,
    }));
    state.totalData = action.data[0].counters[0].count;
  } else {
    state.totalData = state.counters.filter(
      (f) => f.label.toLowerCase() === state.activeCounter.toLowerCase()
    )[0].count;
  }

  state.indents = action.data[0].indents.map((m, mdx) => ({
    ...m,
    truck_quantity: m.available_vehicles ? m.available_vehicles : "",
    // freightRate: 50000,
    reportingTime: moment(m.reporting_date).format("DD MMM 'YY @HH:mm"),
    status: { text: toSentenceCase(m.state), color: getStatusColor(m.state) },
    transporterName: m.transporter && m.transporter.name,
    client: m.client_client ? m.client_client : "N/A",
    destinationCity: m.destination.city ? m.destination.city : "N/A",
    active: mdx === 0 && true,
    acceptDisabled: m.state === "REJECTED",
    // || !(
    //     m.available_vehicles &&                  //Use this condition to disable accept button conditionally
    //     Boolean(m.available_vehicles) &&
    //     m.available_vehicles <= m.truck_quantity
    // ),
    acceptRejectHidden: !(m.state === "OPEN" || m.state === "REJECTED"),
    rejectDisabled: m.state === "REJECTED",
    updateDisabled: m.state === "CLOSED" || m.state === "PARTIALLY CLOSED",
    invoiceHidden: m.state === "OPEN" || m.state === "REJECTED",
    invoiceDisabled: m.state === "CLOSED" || m.state === "PARTIALLY CLOSED",
    updateDriverHidden: m.state === "OPEN" || m.state === "REJECTED",
    updateDocumentHidden: m.state === "OPEN" || m.state === "REJECTED",
    srcname: m.source.name,
    destname: m.destination.name,
    vehicle_type: m.vehicle_type.vehicle_type,
    requestedVehicle: m.requested_vehicle,
  }));
};

const acceptIndentResponseHandler = (state, response) => {
  if (response.response) {
    console.log("indent accepted", response.response);
    state.alertData = {
      open: true,
      type: "success",
      status: "Success",
      message: "Indent Accepted",
    };
  } else if (response.error) {
    console.log("indent could not be accepted", response.error);
    state.alertData = {
      open: true,
      type: "error",
      status: "Failed",
      message: "Indent could not be Accepted",
    };
  }
};

const rejectIndentResponseHandler = (state, response) => {
  if (response.response) {
    console.log("indent rejected", response.response);
    state.alertData = {
      open: true,
      type: "success",
      status: "Success",
      message: "Indent Rejected",
    };
  } else if (response.error) {
    console.log("indent could not be rejected", response.error);
    state.alertData = {
      open: true,
      type: "error",
      status: "Failed",
      message: "Indent could not be rejected",
    };
  }
};

const updateTableLoadingReducer = (state, tableLoadingState) => {
  state.tableLoading = tableLoadingState;
};

const updateAvailableVehiclesReducer = (state, action) => {
  console.log(action);

  const indent = state.indents[action.data.id];

  indent.available_vehicles = action.data.data;
  indent.acceptDisabled = !(
    indent.available_vehicles &&
    Boolean(indent.available_vehicles) &&
    indent.available_vehicles <= indent.requested_vehicle
  );
};

const updatePaginationReducer = (state, action) => {
  const { page: offset, rowsPerPage: limit } = action.pagination;
  state.pagination.offset = offset;
  state.pagination.limit = limit;
};

const updateDocumentsHandler = (state, action) => {
  if (action.response.response?.display?.status === 200) {
    if (action.response.response.display.message === "success") {
      state.alertData = {
        open: true,
        type: "success",
        status: "Success",
        message: "Document Updated",
      };
    } else {
      state.alertData = {
        open: true,
        type: "warning",
        status: "Error",
        message: action.response.response?.display?.message,
      };
    }
  } else {
    state.alertData = {
      open: true,
      type: "error",
      status: "Error",
      message: action.response.response?.display?.message,
    };
  }
};

const transporterReducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.TRANSPORTER_UPDATE_AVAILABLE_VEHICLES:
      updateAvailableVehiclesReducer(draft, action);
      break;
    case actionTypes.SET_LOADING:
      setLoading(draft, action.response);
      break;
    case actionTypes.START_FETCH_INDENT:
      updateTableLoadingReducer(draft, true);
      break;
    case actionTypes.FINISH_FETCH_INDENT:
      updateTableLoadingReducer(draft, false);
      break;
    case actionTypes.UPDATE_TRANSPORTER_INDENT_DATA:
      updateTransporterIndentDataReducer(draft, action);
      break;
    case actionTypes.UPDATE_TRANSPORTER_ACTIVE_COUNTER:
      updateTransporterActivecounterReducer(draft, action);
      break;
    case actionTypes.RESET_INDENT_DATA:
      resetTransporterIndentReducer(draft);
      break;
    case actionTypes.UPDATE_TRANSPORTER_PAGINATION_DATA:
      updatePaginationReducer(draft, action);
      break;
    case actionTypes.INDENT_ACCEPT_RESPONSE:
      acceptIndentResponseHandler(draft, action);
      break;
    case actionTypes.INDENT_REJECT_RESPONSE:
      rejectIndentResponseHandler(draft, action);
      break;
    case actionTypes.UPDATE_VEHICLE_DOCUMENTS:
      updateDocumentsHandler(draft, action);
      break;
    default:
      return draft;
  }
}, transporterState);

export default transporterReducer;
