import * as actionTypes from "./actionTypes";
import { transporter as ClientService } from "../../Shared/API/services";

export const startTransporterFetchIndents = () => ({
  type: actionTypes.START_TRANSPORTER_FETCH_INDENT,
});

export const finishTransporterFetchIndents = () => ({
  type: actionTypes.FINISH_TRANSPORTER_FETCH_INDENT,
});

export const updateAvailableVehicles = (data) => ({
  type: actionTypes.TRANSPORTER_UPDATE_AVAILABLE_VEHICLES,
  data,
});

export const setTransporterIndentData = (indents) => ({
  type: actionTypes.UPDATE_TRANSPORTER_INDENT_DATA,
  ...indents,
});

export const updateTransporterActiveCounter = (label) => ({
  type: actionTypes.UPDATE_TRANSPORTER_ACTIVE_COUNTER,
  label,
});

export const setTransporterPaginationData = (pagination) => ({
  type: actionTypes.UPDATE_TRANSPORTER_PAGINATION_DATA,
  pagination,
});

export const clearTransporterIndents = () => ({
  type: actionTypes.RESET_INDENT_DATA,
});

//get indents
export const getTransporterIndents = (params) => {
  return async (dispatch) => {
    let payload = {};
    if (params) {
      payload = payloadBuilder(params);
    }
    dispatch(startTransporterFetchIndents());
    const { response } = await ClientService.getFilteredIndents({}, payload);
    if (response) {
      dispatch(setTransporterIndentData({ ...response }));
    }
    dispatch(finishTransporterFetchIndents());
  };
};

const payloadBuilder = (params) => {
  let payload = {};
  Object.keys(params).forEach((item) => {
    if (params[item]) {
      payload[item] = params[item];
    }
  });

  return payload;
};

export const getFilteredTransporter = (filterData, params) => {
  return async (dispatch) => {
    dispatch(startTransporterFetchIndents());
    const { response } = await ClientService.getFilteredIndents(
      filterData,
      params
    );
    if (response) {
      dispatch(setTransporterIndentData({ ...response }));
    }
    dispatch(finishTransporterFetchIndents());
  };
};

export const getFilteredData = (filterData) => {
  return async (dispatch) => {
    dispatch(startTransporterFetchIndents());
    const { response } = await ClientService.getIndents(filterData);
    if (response) {
      dispatch(setTransporterIndentData({ ...response }));
    }
    dispatch(finishTransporterFetchIndents());
  };
};

//*************Accept Indent***************//
export const acceptIndent = (indent, value) => {
  let payload = {
    indentId: indent?._id,
    indent:indent?.indent,
    driver_name: value?.driver_name,
    driver_phone: value?.driver_phone,
    vehicle: value?.vehicle,
    availableVehicle: indent.available_vehicles,
  };
  return async (dispatch) => {
    const response = await ClientService.acceptIndent(payload);
    if (response) {
      dispatch(acceptIndentResponse(response));
      dispatch(getTransporterIndents());
    }
  };
};

export const acceptIndentResponse = (response) => {
  console.log(response, "accept indent response");
  return {
    type: actionTypes.INDENT_ACCEPT_RESPONSE,
    response,
  };
};

//*************Reject Indent***************//
export const rejectIndent = (indent, rejectReason) => {
  return async (dispatch) => {
    let payloadData = {
      indentId: indent,
      rejectReason: rejectReason,
    };
    const response = await ClientService.rejectIndent(payloadData);
    if (response) {
      dispatch(rejctIndentResponse(response));
      dispatch(getTransporterIndents());
    }
  };
};

export const rejctIndentResponse = (response) => {
  if (response.error) {
    console.log(response.error, "Reject Error");
    return {
      type: actionTypes.INDENT_REJECT_RESPONSE,
      response,
    };
  } else {
    console.log(response, "Rejected Successfully");
    return {
      type: "",
      response,
    };
  }
};

//*************Add Driver Details***************//
export const addDriverDetails = (indent) => {
  return async (dispatch) => {
    const response = await ClientService.addDriverDetails(indent);
    if (response) {
      dispatch(addDriverResponse(response));
      dispatch(getTransporterIndents());
      //dispatch(startTrip(indent))
    }
  };
};

export const addDriverResponse = (response) => {
  console.log(response, "add driver response");
  return {
    type: "",
    response,
  };
};

//*************Add Vehicle Details (Documents)***************//
export const addVehicleDetails = (payload) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const response = await ClientService.addVehicleDetails(payload);
    if (response) {
      dispatch(addVehicleResponse(response));
      dispatch(getTransporterIndents());
      dispatch(setLoading(false));
    }
  };
};

export const addVehicleResponse = (response) => {
  return {
    type: "UPDATE_VEHICLE_DOCUMENTS",
    response,
  };
};

export const setLoading = (response) => {
  return {
    type: "SET_LOADING",
    response,
  };
};

//*************SCT Trip Start *****************//

export const startTrip = (indentDetails) => {
  let payload = `indent_id=${indentDetails[0]._id}&tel=${indentDetails[0].driver_phone}`;
  return async (dispatch) => {
    const response = await ClientService.sctTripStart(payload);
    if (response) {
      console.log("Trip start api", response);
    }
  };
};
