import produce, { setAutoFreeze } from "immer";
import * as actionTypes from "../Actions/actionTypes";
import moment from "moment";

import { toSentenceCase, isObject } from "../../Shared/Helper/utility";
import { getStatusColor } from "../../Shared/Helper/MaterialTheme";

setAutoFreeze(false);

const indentState = {
  indents: [],
  counters: [],
  filters: {},
  vehicleType: [],
  sources: [],
  destinations: [],
  allLocations: [],
  clients: [],
  transporters: [],
  tableLoading: false,
  totalData: 0,
  activeCounter: "total",
  pagination: {
    limit: 10,
    offset: 0,
  },
  createIndentStatus: false,
  createSingleIndentError: [],
  createSingleIndentStatus: false,
  bulkUploadRowErrors: [],
  cancelIndentStatus: false,
  alert: {
    open: false,
    type: "",
    status: "",
    message: "",
  },
  lookupAddressResponse: {
    address: "",
    coords: [],
    state: "",
    city: "",
    district: "",
    pincode: "",
  },
};

const updateIndentActivecounterReducer = (state, action) => {
  state.activeCounter = action.label;
  state.counters.forEach((counter) => {
    if (action.label === counter.label) {
      counter.active = true;
    } else {
      counter.active = false;
    }
  });
};

const createIndentStatusHandler = (state, action) => {
  switch (action.ReqType) {
    case "BULK":
      if (action.type === actionTypes.CREATE_INDENT_SUCCESS) {
        state.createIndentStatus = true;
        state.alert = {
          open: true,
          type: "success",
          status: "Success",
          message: "Bulk Indent Created",
        };
      } else if (action.type === actionTypes.CREATE_INDENT_FAILURE) {
        state.createIndentStatus = false;
        state.bulkUploadRowErrors = action.response.error.display.result;
        console.log("CREATION ERROR", action);
        state.alert = {
          open: true,
          type: "error",
          status: "Failure",
          message: "Indent Could not be Created",
        };
      }
      break;
    case "SINGLE":
      if (action.type === actionTypes.CREATE_INDENT_SUCCESS) {
        state.createSingleIndentStatus = true;
        state.alert = {
          open: true,
          type: "success",
          status: "Success",
          message: "Single Indent Created",
        };
      } else if (action.type === actionTypes.CREATE_INDENT_FAILURE) {
        state.createSingleIndentStatus = false;
        state.createSingleIndentError = action.response.error.display.result;
        state.alert = {
          open: true,
          type: "error",
          status: "Failure",
          message: "Indent Could not be Created",
        };
      }
      break;
    default:
      console.log(action, "Something's not right !!");
  }
};

const editIndentStatusHandler = (state, action) => {
  console.log(action, "In reducer");
  if (action.response.response.display.status === 200) {
    state.alert = {
      open: true,
      type: "success",
      status: "Success",
      message: action.response.response.display.message,
    };
  } else {
    state.alert = {
      open: true,
      type: "error",
      status: "Failure",
      message: action.response.response.display.message,
    };
  }
};

const partialFailureHandler = (state, action) => {
  state.createIndentStatus = false;
  state.bulkUploadRowErrors = action.response.response.data;
};

const cancelIndentStatusHandler = (state, action) => {
  if (action.type === actionTypes.CANCEL_INDENT_SUCCESS) {
    state.cancelIndentStatus = true;
    console.log("indent cancelled");
    state.alert = {
      open: true,
      type: "success",
      status: "Success",
      message: "Indent Cancelled",
    };
  } else {
    state.cancelIndentStatus = false;
    console.log("indent cancel failure");
    state.alert = {
      open: true,
      type: "error",
      status: "Failure",
      message: "Indent Could not be Cancelled",
    };
  }
};

const reInitIndentStatusHandler = (state, action) => {
  if (action.type === actionTypes.REINIT_INDENT_SUCCESS) {
    console.log("Indent Reinitiated success");
    state.alert = {
      open: true,
      type: "success",
      status: "Success",
      message: "Indent Reinitiated",
    };
  } else {
    console.log("Indent Reinitiated failed");
    state.alert = {
      open: true,
      type: "error",
      status: "Falied",
      message: "Indent Reinit Failed",
    };
  }
};

const resetStateHandler = (state, action) => {
  state.createIndentStatus = false;
  state.createSingleIndentError = [];
  state.createSingleIndentStatus = false;
  state.bulkUploadRowErrors = [];
  state.cancelIndentStatus = false;
};

const updateVehicleTypeReducer = (state, action) => {
  state.vehicleType = action.vehicleData;
};

const saveFilterReducer = (state, action) => {
  state.filters = action.filters;
};

const updateIndentDataReducer = (state, action) => {
  if (action.counter) {
    state.counters = action.counter.map((m, mdx) => ({
      ...m,
      color: getStatusColor(m.label),
      active: mdx === 0 && true,
    }));
    state.totalData = action.counter[0].count;
  } else {
    state.totalData = state.counters.filter(
      (f) => f.label.toLowerCase() === state.activeCounter.toLowerCase()
    )[0].count;
  }

  state.indents = action.indent.map((m) => ({
    ...m,
    freightRate: 50000,
    reportingTime: moment(m.reporting_date).format("DD MMM 'YY @HH:mm"),
    status: { text: toSentenceCase(m.state), color: getStatusColor(m.state) },
    srcname: m.source && m.source.name,
    drops: m?.drops?.map((item) => item?.address) || "N/A",
    invoice_time: m?.driver_details?.[0]?.transporter_attachment?.INV?.[0]?.time
      ? moment(
          m?.driver_details?.[0]?.transporter_attachment?.INV?.[0]?.time
        ).format("DD MMM 'YY @HH:mm")
      : "N/A",
    destination: [m.destination],
    destname: m.destination && m.destination.name,
    destCity: m.destination && m.destination.city,
    transporterName: m.transporter && m.transporter.name,
    clientName: m.extra
      ? m.extra.client_name &&
        m.extra.client_name[0] &&
        m.extra.client_name[0].client_name
      : "",
    client_name: m.extra ? m.extra.client_name && m.extra.client_name : "",
    vehicleType: isObject(m.vehicle_type)
      ? m.vehicle_type.vehicle_type
      : m.vehicle_type,
    viewDisabled:
      m.state !== "ACCEPTED" &&
      m.state !== "PARTIALLY CLOSED" &&
      m.state !== "PARTIALLY ACCEPTED",
    reinitDisabled: !(
      m.state === "REJECTED" || m.state === "PARTIALLY ACCEPTED"
    ),
    resendDisabled:
      m.state === "REJECTED" ||
      m.state === "PARTIALLY CLOSED" ||
      m.state === "CLOSED",
    editDisabled: m.state !== "OPEN",
  }));
};

const addTransporterResponseHandler = (state, action) => {
  if (action.response.response) {
    if (action.response.response.display.status === 200) {
      state.alert = {
        open: true,
        type: "success",
        status: "Success",
        message: action.response.response.display.message,
      };
    } else {
      state.alert = {
        open: true,
        type: "success",
        status: "Success",
        message: "something was not right",
      };
    }
  } else if (action.response.error) {
    if (action.response.error.display.status === 409) {
      state.alert = {
        open: true,
        type: "error",
        status: "Error",
        message: "Duplicate Transporter Found",
      };
    } else {
      state.alert = {
        open: true,
        type: "error",
        status: "error",
        message: "Something went wrong",
      };
    }
  }
  // switch(action){
  //   case action.response.response :
  //     if(action.response.response.display.status === 200){
  //       state.alert = {
  //         open : true,
  //         type : 'success',
  //         status : 'Success',
  //         message : action.response.display.message
  //       }
  //     }
  //     break;
  //   case action.response.error :
  //     if(action.response.response.display.status === 409){
  //       state.alert = {
  //         open : true,
  //         type : 'error',
  //         status : 'Failed',
  //         message : 'Duplicate Transporter Found'
  //       }
  //     }
  //     break;
  //   default :
  //     state.alert = {
  //       open : true,
  //       type : 'error',
  //       status : 'Failed',
  //       message : 'Transporter Could not be added'
  //     }
  // }
};

const lookupResponseHandler = (state, response) => {
  if (response.response.length) {
    state.lookupAddressResponse.address =
      response.response[0].formatted_address;
    state.lookupAddressResponse.coords = response.response[0].lat_lon;
    state.lookupAddressResponse.state = response.response[0].state;
    state.lookupAddressResponse.city = response.response[0].city;
    state.lookupAddressResponse.country = response.response[0].country;
    state.lookupAddressResponse.district = response.response[0].district;
    state.lookupAddressResponse.pincode = response.response[0].pincode;
  } else {
    state.lookupAddressResponse = {};
  }
};

const resendEmailHandler = (state, response) => {
  if (response.response.response.display.message === "Success") {
    state.alert = {
      open: true,
      type: "success",
      status: "Success",
      message: "Email Sent Again",
    };
  } else {
    state.alert = {
      open: true,
      type: "error",
      status: "Failed",
      message: "Email could not be sent",
    };
  }
};

const createLocationHandler = (state, response, type) => {
  if (type === "success") {
    state.alert = {
      open: true,
      type: "success",
      status: "Success",
      message: "Location Added",
    };
    state.lookupAddressResponse = {};
    console.log(response, "from reducer");
  } else if (type === "failed") {
    if (response.response.error.display.status === 409) {
      state.alert = {
        open: true,
        type: "error",
        status: "Failed",
        message: "Duplicate location",
      };
      state.lookupAddressResponse = {};
    } else {
      state.alert = {
        open: true,
        type: "error",
        status: "Failed",
        message: "Could not add location",
      };
      state.lookupAddressResponse = {};
    }
    console.log(response, "from reducer");
  }
};

const addVehicleTypeResponseHandler = (state, response) => {
  console.log(response, "REDUCER FOR TYPE");
  if (response.response.response) {
    state.alert = {
      open: true,
      type: "success",
      status: "Success",
      message: response.response.response.display.message,
    };
  } else {
    if (response.response.error.display.status === 409) {
      state.alert = {
        open: true,
        type: "error",
        status: "Error",
        message: "Duplicate value",
      };
    } else {
      state.alert = {
        open: true,
        type: "error",
        status: "Error",
        message: "could not add vehicle type",
      };
    }
  }
};

const updateTableLoadingReducer = (state, tableLoadingState) => {
  state.tableLoading = tableLoadingState;
};

const updateSrcDataReducer = (state, action) => {
  state.sources = action.sourceLocations;
};

const updateDestDataReducer = (state, action) => {
  state.destinations = action.destinationLocations.filter((item) => item.name);
};

const updateLocationReducer = (state, action) => {
  state.allLocations = action.allLocations;
};

const updateCustomerDataReducer = (state, action) => {
  console.log(action);
  state.clients = action.customerData.filter(
    (item) => item.client_name && item.client_name?.length > 0
  );
  state.clients = state.clients.map((item) => {
    if (typeof item.client_code !== undefined) {
      item.displayName = `${item.client_name} - ${item.client_code}`;
      return item;
    } else {
      item.displayName = item.client_name;
      return item;
    }
  });
};

const updateTransporterReducer = (state, action) => {
  state.transporters = action.transporterList;
};

const resetIndentReducer = (state) => {
  state.indents = [];
};

const resetTransporterReducer = (state) => {
  state.transporters = [];
};

const resetDestinationReducer = (state) => {
  state.destinations = [];
};

const resetCustomerReducer = (state) => {
  state.clients = [];
};

const clearAlertDataHandler = (state, action) => {
  state.alert = {
    open: false,
    type: "",
    status: "",
    message: "",
  };
};

const updatePaginationReducer = (state, action) => {
  const { page: offset, rowsPerPage: limit } = action.pagination;
  state.pagination.offset = offset;
  state.pagination.limit = limit;
};

const indentReducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_INDENT_ACTIVE_COUNTER:
      updateIndentActivecounterReducer(draft, action);
      break;
    case actionTypes.START_FETCH_INDENT:
      updateTableLoadingReducer(draft, true);
      break;
    case actionTypes.FINISH_FETCH_INDENT:
      updateTableLoadingReducer(draft, false);
      break;
    case actionTypes.SAVE_FILTERS:
      saveFilterReducer(draft, action);
      break;
    case actionTypes.UPDATE_VEHICLE_TYPE_DATA:
      updateVehicleTypeReducer(draft, action);
      break;
    case actionTypes.UPDATE_INDENT_DATA:
      updateIndentDataReducer(draft, action);
      break;
    case actionTypes.UPDATE_SRC_LOCATION_DATA:
      updateSrcDataReducer(draft, action);
      break;
    case actionTypes.UPDATE_DEST_LOCATION_DATA:
      updateDestDataReducer(draft, action);
      break;
    case actionTypes.UPDATE_LOCATION_DATA:
      updateLocationReducer(draft, action);
      break;
    case actionTypes.UPDATE_CUSTOMER_DATA:
      updateCustomerDataReducer(draft, action);
      break;
    case actionTypes.UPDATE_TRANSPORTER_DATA:
      updateTransporterReducer(draft, action);
      break;
    case actionTypes.RESET_INDENT_DATA:
      resetIndentReducer(draft);
      break;
    case actionTypes.RESET_TRANSPORTER_DATA:
      resetTransporterReducer(draft);
      break;
    case actionTypes.RESET_DESTINATION_DATA:
      resetDestinationReducer(draft);
      break;
    case actionTypes.RESET_CUSTOMER_DATA:
      resetCustomerReducer(draft);
      break;
    case actionTypes.UPDATE_PAGINATION_DATA:
      updatePaginationReducer(draft, action);
      break;
    case actionTypes.CREATE_INDENT_SUCCESS:
    case actionTypes.CREATE_INDENT_FAILURE:
      createIndentStatusHandler(draft, action);
      break;
    case actionTypes.EDIT_INDENT_RESPONSE:
      editIndentStatusHandler(draft, action);
      break;
    case actionTypes.CANCEL_INDENT_SUCCESS:
    case actionTypes.CANCEL_INDENT_FAILURE:
      cancelIndentStatusHandler(draft, action);
      break;
    case actionTypes.REINIT_INDENT_FAILURE:
    case actionTypes.REINIT_INDENT_SUCCESS:
      reInitIndentStatusHandler(draft, action);
      break;
    case actionTypes.RESET_STATE:
      resetStateHandler(draft, action);
      break;
    case actionTypes.CREATE_INDENT_PARTIAL_FAILURE:
      partialFailureHandler(draft, action);
      break;
    case actionTypes.ADD_TRANSPORTER_SUCCESS:
    case actionTypes.ADD_TRANSPORTER_FAILED:
      addTransporterResponseHandler(draft, action);
      break;
    case actionTypes.LOOKUP_ADDRESS_RESPONSE:
      lookupResponseHandler(draft, action);
      break;
    case actionTypes.ADD_VEHICLE_TYPE_RESPONSE:
      addVehicleTypeResponseHandler(draft, action);
      break;
    case actionTypes.CREATE_LOCATION_SUCCESS:
      createLocationHandler(draft, action, "success");
      break;
    case actionTypes.CREATE_LOCATION_FAILED:
      createLocationHandler(draft, action, "failed");
      break;
    case actionTypes.RESEND_EMAIL_RESPONSE:
      resendEmailHandler(draft, action);
      break;
    case actionTypes.CLEAR_ALERT_DATA:
      clearAlertDataHandler(draft, action);
      break;
    default:
      return draft;
  }
}, indentState);

export default indentReducer;
