import loadable from '@loadable/component'

const routes = [
    {
        path: "/",
        component: loadable(() => import('./Containers/Login')),
        exact: true
    },
    {
        path: "/indents",
        component: loadable(() => import('./Containers/Indent'))
    },
    {
        path: "/master",
        component: loadable(() => import('./Containers/MasterData'))
    },
    {
        path: "/shipments",
        component: loadable(() => import('./Containers/Shipments'))
    },
    {
        path: "/bookings",
        component: loadable(() => import('./Containers/Book'))
    },
    {
        path: "/transporter",
        component: loadable(() => import('./Containers/Transporter'))
    },

]

export default routes