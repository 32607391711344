import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';

import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import NavButton from '../Components/UI/Buttons/NavButton'

import * as actions from '../Store/Actions/index'

const useStyles = makeStyles(theme => ({
    iconButton: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    navGroup: {
        display: 'flex',
        marginRight: theme.spacing(4)
    },
    appBar : {
        alignItems: 'center'
    }
}))


const SctNavBar = (props) => {
    const classes = useStyles()

    const history = useHistory()

    let nav = null

    if (history.location.pathname !== '/') {
        nav = (
            <AppBar elevation={0} color="transparent" position="static" className={classes.appBar}>
                <Toolbar m={1} disableGutters={true}>
                    <div className={classes.navGroup}>
                        {/* <NavButton color="primary" to="/shipments">Shipments</NavButton> */}
                        {props.user !== 'transporter' && <NavButton color="primary" to="/indents">Indents</NavButton>}
                        {props.config.showMasterPage && <NavButton color="primary" to="/master">Master</NavButton>}
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return nav
}

const mapStateToProps = state => ({
    user: state.auth.user,
    client: state.auth.client,
    viaLogin: state.auth.viaLogin,
    config: state.auth.config
})

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SctNavBar)