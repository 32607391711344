import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const options = {
    palette: {
        // type: 'dark',
        primary: {
            main: '#43425D'
        },
        secondary: {
            main: '#9BC5F8',
            light: '#F0F7FF',
            dark: '#4949F0'
        },
        text: {
            light: '#F7F7F7',
            main: '#DBDBDB',
            dark: '#919191'
        },
        success: {
            main: '#28AE14',
            light: '#C5FFCB'
        },
        error: {
            main: '#FE5858',
            light: '#FFDADA'
        },
        info: {
            main: '#009CB5'
        },
        warning: {
            main: '#EDBD00'
        }
    },
    typography: {
        fontFamily: 'Montserrat'
    },
}

const Muitheme = createMuiTheme(options)

export const theme = responsiveFontSizes(Muitheme)

export function spacing(t = 0, r = 0, b = 0, l = 0) {
    return theme.spacing(t, r, b, l)
}

export const getColor = (color) => theme.palette[color.split('.')[0]][color.split('.')[1]]

export const getStatusColor = (label) => {

    const status = label.toLowerCase()

    switch (status) {
        case 'all':
        case 'total': return theme.palette.common.white
        case 'open': return theme.palette.warning.main
        case 'pending': return theme.palette.warning.main
        case 'rejected': return theme.palette.error.main
        case 'accepted': return theme.palette.info.main
        case 'closed': return theme.palette.success.main
        case 'partially closed': return theme.palette.success.main
        case 'partially accepted': return theme.palette.info.main
        default: return theme.palette.text.dark
    }
}

export const getConsentColor = (consent) => {

    switch (consent) {
        case 'ALLOWED': return theme.palette.success.main
        case 'PENDING': return theme.palette.error.main
        default: return theme.palette.primary.main
    }
}