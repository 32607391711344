import * as actionTypes from './actionTypes'

export const setAlert = (alert) => (
    {
        type: actionTypes.SET_ALERT_MESSAGE,
        alert
    }
)

export const resetAlert = () => (
    {
        type: actionTypes.RESET_ALERT_MESSAGE
    }
)