import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';

import { AppBar, Toolbar, Typography, Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as Logo } from '../assets/Intugine.svg'
import Exit from '../Components/UI/Icons/Exit'
import ChevronDown from '../Components/UI/Icons/ChevronDown'
import Profile from '../Components/UI/Icons/Profile'
import NavButton from '../Components/UI/Buttons/NavButton'

import * as actions from '../Store/Actions/index'

const useStyles = makeStyles(theme => ({
    list: {
        '& .MuiListItemText-primary': {
            color: theme.palette.primary.main,
            fontWeight: 500
        },
        '& .MuiListItemText-secondary': {
            color: theme.palette.text.dark,
            fontWeight: 500
        }
    },
    logout: {
        paddingBottom: theme.spacing(0.25)
    },
    iconButton: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    navGroup: {
        display: 'flex',
        marginRight: theme.spacing(4)
    }
}))

const NavBar = (props) => {
    const classes = useStyles()

    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState(null);

    const menuOpenHandler = event => {
        setAnchorEl(event.currentTarget);
    };

    const menuCloseHandler = () => {
        setAnchorEl(null);
    };

    const logoutHandler = () => {
        props.logout()
        history.push('/')
    }

    let nav = null

    if (history.location.pathname !== '/') {
        nav = (
            <AppBar elevation={0} color="transparent" position="static">
                <Toolbar m={1} disableGutters={true}>
                    <Typography component="h6" variant="subtitle2">
                        <Box m={1}>
                            <Logo height="45" />
                        </Box>
                    </Typography>
                    <Typography component="h6" variant="h6">
                        <Box m={1} color="primary.main" fontWeight={400}>
                            INDENT
                        </Box>
                    </Typography>
                    <Typography component="h6" variant="h6">
                        <Box ml={0.25} mb={0.5} color="primary.main" fontWeight={300}>
                            |
                        </Box>
                    </Typography>
                    <Typography component="h6" style={{ flexGrow: 1 }} variant="h6">
                        <Box m={1} color="primary.main" fontWeight={600}>
                            Intugine
                        </Box>
                    </Typography>
                    <div className={classes.navGroup}>
                        {/* <NavButton color="primary" to="/shipments">Shipments</NavButton> */}
                        {props.user !== 'transporter' && <NavButton color="primary" to="/indents">Indents</NavButton>}
                        {props.config.showMasterPage && <NavButton color="primary" to="/master">Master</NavButton>}
                    </div>
                    {(history.location.pathname !== '/transporter' && props.viaLogin) && (
                        <div className={classes.logout}>
                            <IconButton edge="start" className={classes.iconButton} onClick={menuOpenHandler} disableRipple disableFocusRipple aria-controls="profile-menu" aria-haspopup="true">
                                <Profile mycolor="primary.main" fontSize="large" />
                                <ChevronDown mycolor="primary.main" fontSize="small" />
                            </IconButton>
                            <Menu id="profile-menu"
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={menuCloseHandler}
                                keepMounted
                            >
                                <MenuItem>
                                    <ListItemIcon>
                                        <Profile mycolor="primary.main" fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText className={classes.list} primary={props.user} secondary={props.client} />
                                </MenuItem>
                                <MenuItem onClick={logoutHandler}>
                                    <ListItemIcon>
                                        <Exit mycolor="primary.main" fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText className={classes.list} primary="Logout" />
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        )
    } else if (anchorEl) {
        setAnchorEl(null);
    }

    return nav
}

const mapStateToProps = state => ({
    user: state.auth.user,
    client: state.auth.client,
    viaLogin: state.auth.viaLogin,
    config: state.auth.config
})

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
